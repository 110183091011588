.navbar-logo {
    min-width: 1.5rem;
    min-height: 2.5rem;
}

@media only screen and (max-width: 600px) {
    .navbar-logo {
      min-height: auto;
      min-width: auto;
    }
}