.navbar-logo-protected {
    min-width: 1.5rem;
    min-height: 2.5rem;
}

@media only screen and (max-width: 600px) {
    .navbar-logo-protected {
      min-height: auto;
      min-width: auto;
    }

    /* .user-logo-protected {
        min-height: auto;
        min-width: auto;
    } */
}

.fa.fa-folder{
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.fa.fa-history{
    color: black;
    -webkit-text-stroke-width: 0px;
    /* -webkit-text-stroke-color: black; */
}

.fa.fa-book{
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.fa.fa-pen{
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.fa.fa-file-invoice{
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}