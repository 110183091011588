:root {
    --amplify-primary-color: #FF5A5F;
    --amplify-primary-tint: #FF5A5F;
    --amplify-primary-shade: #FF5A5F;
}

.parent-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
}

.red-wrapper {
    min-height: 10rem;
    background-color: #FF5A5F;
}

.custom-red-bg {
    background-color: #FF5A5F;
    color: #FF5A5F;
}

.child-wrapper {
    flex: 1;
}

.container {
    min-width: 80%;
}

.flex-div-column {
    flex-direction: column;
}

.flex-div-row {
    flex-direction: column;
}

.doFadeIn {
    -webkit-animation: fadeInSlide forwards ease-in 150ms;
    animation: fadeInSlide forwards ease-in 150ms;
}

.doFadeOut {
    -webkit-animation: fadeOutSlideDown forwards ease-out 150ms;
    animation: fadeOutSlideDown forwards ease-out 150ms;
}

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    /* background-color: white; */
    /* color:black; */
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

@keyframes fadeInSlide {
    0% {
        opactity: 0;
        transform: translateX(-100px);
    }
    100% {
        opactity: 1;
        transform: translateX(0);
    }
}